<template>
  <div class="sign-in-page-gide">
    <b-container class="container p-0">
      <div class="row no-gutters gideAuthRow">
        <div class="col-md-6 col-sm-4 col-xs-12 text-center  d-sm-block mobileView" :class="showLoginBtnMob ? '': 'desktopContent  '" style="padding-right: 10px;">
          <div class="text-white verticleCenter" style="right: 68px;">
            <img src="../../assets_gide/img/logo/gide_logo.png" class="img-fluid" alt="logo">
            <div class="mt-4 font-size-14 font-weight-normal gideLogoCaption">
              <span class="line3 mobileContentHide">
                <br>
                <b-button class="mt-5" @click="showLoginBtnMob = false">Login</b-button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-8 bg-white col-xs-12" :class="showLoginBtnMob ? 'showInMobile': ''">
          <div :class="mobileView ? 'sign-in-from': 'sign-in-from gide_scroll_view_form verticleCenter'">
            <img :src="gidelogo" :class="LogoClass+ ' hideFromDesktop'">
            <router-view>
            </router-view>
          </div>
        </div>
        <div class="cookieContainer" v-if="showCookieDialog">
          <Cookie @emitCloseCookieModal="closeCookieModal" />
        </div>
      </div>
    </b-container>
  </div>
</template>
<style lang="scss">
  .sign-in-page-gide{
    background-color: var(--iq-primary);
    height: 100vh;
    .gideAuthRow {
      height: 100vh;
      margin: 0 !important;
    }
    .verticleCenter {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: max-content;
      left: 0;
      right: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .cookieContainer {
    position: absolute;
    right: 0;
    width: 438px;
    bottom: 0;
  }
  .gideLogoCaption {
    .line1 {
      font-size: 35px;
      line-height: 44px;
      font-weight: 600;
    }
    .line2 {
      position: relative;
      top: 11px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  @media (max-width:576px) {
    .background {
      background-image: url(https://gide-assets.s3.amazonaws.com/GideWhiteNRed.jpeg);
      background-repeat: no-repeat;
      background-position: top;
      background-size: 157px;
      background-position-y: 51px;
    }
    .desktopContent, .showInMobile {
      display: none;
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
    .mobileView {
      .verticleCenter {
        right: 0 !important;
        padding: 0px 16px;
        font-size: 14px;
        .line1 {
          font-size: 20px;
          line-height: 34px;
        }
        .line2 {
          font-size: 14px;
        }
      }
    }
    .line3 {
      .btn {
        background-color: var(--iq-primary-hover) !important;
        border-color: var(--iq-primary-hover) !important;
        color: var(--iq-primary) !important;
      }
    }
    .cookieContainer {
      position: absolute;
      right: 0;
      width: 438px;
      bottom: 0;
      width: 100%;
      left: 0;
      .container {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  @media (min-width:475px) {
    .mobileContentHide {
      display: none;
      position: absolute;
      opacity: 0;
      z-index: 0;
      pointer-events: none;
    }
  }
  .gideAuthLoginIcon {
    height: 117px;
    width: 117px;
    object-fit: cover;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  // .sign-in-from {
  //   padding-left: 5px !important;
  //   padding-right: 5px !important;
  // }
  .gideProfileLoginIcon {
    height: 117px;
    width: 117px;
    object-fit: cover;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: auto;
    // margin-top: 4px;
  }
  @media (max-width: 576px){
    .sign-in-from{
      padding:0px !important;
    }
  }
</style>
<script>
import Cookie from "../../views/Cookie.vue"
export default {
  name: "GideAuthLayout",
  components: {
    Cookie
  },
  mounted () {
    const cookies = localStorage.getItem("cookies")
    if (navigator.userAgent.toLowerCase().includes("gide")) {
      // Dont show cookie box for mobile app
      this.showCookieDialog = false
    }
    else if (!cookies) {
      this.showCookieDialog = true
    }

    if (this.$route.path.includes("profile")) {
      this.LogoClass = "gideProfileLoginIcon"
    }
    else {
      this.LogoClass = "gideAuthLoginIcon"
    }

    if (window.screen.width < 476) {
      this.mobileView = true
    }
  },
  data () {
    return {
      showCookieDialog: false,
      showLoginBtnMob: false,
      LogoClass: "gideAuthLoginIcon",
      mobileView: false,
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
    }
  },
  watch: {
    $route (to, from) {
      if (to.path.includes("profile")) {
        this.LogoClass = "gideProfileLoginIcon"
      }
      else {
        this.LogoClass = "gideAuthLoginIcon"
      }
    }
  },
  methods: {
    closeCookieModal () {
      this.showCookieDialog = false
    }
  }
}
</script>
