<template>
  <b-container>
    <b-card>
      <div class="v-card__text pb-0">
        This website uses Cookies &amp; similar technologies, including
        cookies from Sign in with Google, to make our site work and for
        setting preferences like keeping you logged in.
      </div>
      <div class="row cookieeCheckBoxRow">
        <div class="col-12">
          <b-form-checkbox switch class="mr-n2" v-model="cookies.necessary_cookies">
            <span >Necessary Cookies (Google Sign In)</span>
          </b-form-checkbox>
        </div>
        <div class="col-12">
          <b-form-checkbox switch class="mr-n2" v-model="cookies.preference_cookies">
            <span >Preference Cookies (Keep me logged in)</span>
          </b-form-checkbox>
        </div>
      </div>
      <div class="row actionBtnRow">
        <div class="col-12 firstCol">
          <b-button type="button" class="mr-2" @click="save_cookie_policy">
            Save
          </b-button>
          <b-button type="button" @click="save_cookie_policy(true)">
            Accept All
          </b-button>
        </div>
      </div>
    </b-card>
  </b-container>
</template>
<style lang="scss" scoped>
  .card{
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    color: #000;
    span {
      color: white;
    }
  }
  .cookieeCheckBoxRow {
    .col-12 {
      margin-top: 12px;
    }
  }
  .actionBtnRow {
    margin-top: 17px;
    float: right;
    .firseCol {
      text-align: right;
    }
    button {
      width: 101px;
      background-color: #e5262e !important;
      border: none;
    }
  }
.custom-switch .custom-control-label::before{
  width: 20px;
}
.v-card__text{
  font-size: 16px;
}
.cookieeCheckBoxRow{
  span{
    color:#000;
    font-size:16px;
  }
}
</style>
<script>
export default {
  name: "Cookie",
  data: () => ({
    cookies: {
      necessary_cookies: false,
      preference_cookies: false,
      accept_all: false
    }
  }),
  mounted () {},
  methods: {
    /**
     * save cookie policy
    */
    save_cookie_policy (all = false) {
      if (all === true) {
        this.cookies.necessary_cookies = true
        this.cookies.preference_cookies = true
        this.cookies.accept_all = true
      }
      localStorage.setItem("cookies", JSON.stringify(this.cookies))
      this.$emit("emitCloseCookieModal", true)
    }
  }
}
</script>
